import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { useDispatch, useSelector } from "react-redux";
import { Table, Form, Col, Row } from "react-bootstrap";
import Header from "../../Components/Header";
import {
  addPaymentSetting,
  getPaymentSetting,
  updatePaymentSetting,
} from "../../reducers/commonReducer";
import SideBaar from "../../Components/SideBaar";
import PaymentSettingAction from "./PaymentSettingAction";

const PaymentSetting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [upiType, setUpiType] = useState("UPI");
  const [copiedText, setCopiedText] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getPaymentSetting({ type: "UPI" }));
  }, [navigate, dispatch]);
  const { paymentSettings } = useSelector((state) => state.commonReducer);
  // text copy
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text);
    setTimeout(() => setCopiedText(null), 300); // Remove zoom effect after animation
  };
  return (
    <>
      <Row>
        <Col md={2} className="p-0">
          <SideBaar />
        </Col>
        <Col md={10}>
          <Header />
          <div className="container">
            <Row className="mt-4 mb-2 user-table">
              <Col md={6}>
                <h3>Payment Settings</h3>
              </Col>
              <Col md={6}>
                <PaymentSettingAction
                  api={addPaymentSetting}
                  btnTitle={"Add"}
                  btnFloat={"float-right"}
                  upiType={upiType}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Nav fill variant="tabs" defaultActiveKey="UPI">
                <Nav.Item>
                  <Nav.Link
                    eventKey="UPI"
                    onClick={() => {
                      dispatch(getPaymentSetting({ type: "UPI" }));
                      setUpiType("UPI");
                    }}
                  >
                    <b>UPI</b>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="BANK"
                    onClick={() => {
                      dispatch(getPaymentSetting({ type: "BANK" }));
                      setUpiType("BANK");
                    }}
                  >
                    BANK
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="WALLET"
                    onClick={() => {
                      dispatch(getPaymentSetting({ type: "WALLET" }));
                      setUpiType("WALLET");
                    }}
                  >
                    WALLET
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {!!paymentSettings &&
                paymentSettings?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Col md={4} className="mb-2 mt-3">
                        <div className="box-shadow p-2">
                          <Row>
                            <Col md={10}>
                              <p>
                                <b className="text-success">{item?.type} </b>
                              </p>
                              {upiType === "BANK" && (
                                <>
                                  <p className="mb-0">
                                    <b>Bank Holder</b> : {item?.bankHolder}{" "}
                                    <i
                                      onClick={() =>
                                        handleCopy(item?.bankHolder)
                                      }
                                      className={`button-pointer mt-1 mx-2 bi bi-clipboard ${
                                        copiedText === item?.bankHolder
                                          ? "text-copy-zoom"
                                          : ""
                                      }`}
                                    ></i>
                                  </p>
                                  <p className="mb-0">
                                    <b>BankAccount</b> : {item?.bankAccount}{" "}
                                    <i
                                      onClick={() =>
                                        handleCopy(item?.bankAccount)
                                      }
                                      className={`button-pointer mt-1 mx-2 bi bi-clipboard ${
                                        copiedText === item?.bankAccount
                                          ? "text-copy-zoom"
                                          : ""
                                      }`}
                                    ></i>
                                  </p>
                                  <p className="mb-0">
                                    <b>Bank Ifsc</b> : {item?.bankIfsc}{" "}
                                    <i
                                      onClick={() => handleCopy(item?.bankIfsc)}
                                      className={`button-pointer mt-1 mx-2 bi bi-clipboard ${
                                        copiedText === item?.bankIfsc
                                          ? "text-copy-zoom"
                                          : ""
                                      }`}
                                    ></i>
                                  </p>
                                  <p className="mb-0">
                                    <b>BankName</b> : {item?.bankName}{" "}
                                    <i
                                      onClick={() => handleCopy(item?.bankName)}
                                      className={`button-pointer mt-1 mx-2 bi bi-clipboard ${
                                        copiedText === item?.bankName
                                          ? "text-copy-zoom"
                                          : ""
                                      }`}
                                    ></i>
                                  </p>
                                </>
                              )}
                              {upiType === "UPI" && (
                                <p className="mb-0">
                                  <b>UPI</b> : {item?.upi}{" "}
                                  <i
                                    onClick={() => handleCopy(item?.upi)}
                                    className={`button-pointer mt-1 mx-2 bi bi-clipboard ${
                                      copiedText === item?.upi
                                        ? "text-copy-zoom"
                                        : ""
                                    }`}
                                  ></i>
                                </p>
                              )}
                              {upiType === "WALLET" && (
                                <>
                                  <p className="mb-0">
                                    <b>Wallet Phone</b> : {item?.walletPhone}{" "}
                                    <i
                                      onClick={() =>
                                        handleCopy(item?.walletPhone)
                                      }
                                      className={`button-pointer mt-1 mx-2 bi bi-clipboard ${
                                        copiedText === item?.walletPhone
                                          ? "text-copy-zoom"
                                          : ""
                                      }`}
                                    ></i>
                                  </p>
                                  <p className="mb-0">
                                    <b>Wallet Type</b> : {item?.walletType}{" "}
                                    <i
                                      onClick={() =>
                                        handleCopy(item?.walletType)
                                      }
                                      className={`button-pointer mt-1 mx-2 bi bi-clipboard ${
                                        copiedText === item?.walletType
                                          ? "text-copy-zoom"
                                          : ""
                                      }`}
                                    ></i>
                                  </p>
                                </>
                              )}

                              <h3
                                className={
                                  item?.status === 0
                                    ? `text-danger`
                                    : `text-success`
                                }
                              >
                                {item?.status === 0 ? "Inactive" : "Active"}
                              </h3>
                            </Col>
                            <Col md={2}>
                              <PaymentSettingAction
                                api={updatePaymentSetting}
                                id={item?.id}
                                upi={item?.upi}
                                bankName={item?.bankName}
                                bankHolder={item?.bankHolder}
                                bankAccount={item?.bankAccount}
                                bankIfsc={item?.bankIfsc}
                                walletType={item?.walletType}
                                walletPhone={item?.walletPhone}
                                type={item?.type}
                                status={item?.status}
                                upiType={upiType}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </React.Fragment>
                  );
                })}
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PaymentSetting;
