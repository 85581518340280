import React from "react";
import success from "../../images/success.png";

const OfflinePaymentResponse = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const transaction_id = queryParameters.get("id");
  return (
    <>
      <div className={`${!transaction_id && 'justify-content-center'} payment-container mid-of-the-screen`}>
        {transaction_id ? (
          <h1 className="text-center app-font-family">
            <img src={success} alt="Success" />
            <br />
            <span className="text-white">
              Your amount has been successfully deposited. The deposited amount
              will be reflected in your wallet within 10 minutes.
            </span>
          </h1>
        ) : (
          <h1 className="text-center app-font-family">
            <span className="text-white">Contact With Admin</span>
          </h1>
        )}
      </div>
    </>
  );
};

export default OfflinePaymentResponse;
