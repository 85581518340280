import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getUserPayment, restRecord, updateUserPayment } from "../../reducers/commonReducer";

const schema = yup.object().shape({
});

function UserPaymentAction(props) {
  const dispatch = useDispatch();
  const {id, txn_status, amount, reason, paymentType} = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant={txn_status === 0 ? 'info' : `danger`} onClick={handleShow}>
       {txn_status === 0 ? `Pending` : `Failed`}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title>Update Payment Status</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(updateUserPayment(values));
            resetForm({ values: "" });
            dispatch(restRecord())
            dispatch(getUserPayment({paymentType}))
            setShow(false)
          }}
          initialValues={{
            id, txn_status, amount, reason
          }}
        >
          {({
            handleSubmit,
            handleChange,setFieldValue,
            values,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Select Payment Status</Form.Label>
                      <Form.Control
                              as="select"
                              onChange={(e) => setFieldValue('txn_status', +e.target.value)}
                              name="txn_status"
                              className="form-control"
                              value={values.txn_status}
                              isInvalid={!!errors.txn_status}
                            >
                              <option value="">Select Status</option>
                              <option value={0}>Pending</option>
                              <option value={1}>Failed</option>
                              <option value={2}>Success</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.txn_status}
                            </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Amount"
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      isInvalid={!!errors.amount}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.amount}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group >
                    <Form.Label>Reason</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Reason"
                      name="reason"
                      value={values.reason}
                      onChange={handleChange}
                      isInvalid={!!errors.reason}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.reason}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default UserPaymentAction;
