import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getPaymentSetting, getQuestions } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  type: yup.string().required(),
});

function PaymentSettingAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    btnTitle,
    btnFloat,
    id,
    upi,
    bankName,
    bankHolder,
    bankAccount,
    bankIfsc,
    walletType,
    walletPhone,
    status,
    type,
    api,
    upiType
  } = props;
  return (
    <>
      <Button variant="success" onClick={handleShow} className={btnFloat}>
        {btnTitle ? btnTitle : <i className="bi bi-pen-fill"></i>}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{btnTitle ? btnTitle : "Update"}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiresult = await dispatch(api(values));
            if (apiresult?.payload?.status) {
              resetForm({ values: "" });
              dispatch(getPaymentSetting({
                type: upiType
              }));
              setShow(false);
            }
          }}
          initialValues={{
            id,
            upi,
            bankName,
            bankHolder,
            bankAccount,
            bankIfsc,
            walletType,
            walletPhone,
            type,
            status
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3 mt-3">
                {!id && <Col md={6}>
                  <Form.Group>
                    <Form.Label>Select Type</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => setFieldValue("type", e.target.value)}
                      name="type"
                      className="form-control"
                      value={values.type}
                      isInvalid={!!errors.type}
                    >
                      <option value="">Select Type</option>
                      <option value={"UPI"}>UPI</option>
                      <option value={"BANK"}>BANK</option>
                      <option value={"WALLET"}>WALLET</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.type}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>}
                {(type === "UPI" || values.type === "UPI") && <Col md={6}>
                  <Form.Group>
                    <Form.Label>UPI</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter UPI"
                      name="upi"
                      value={values.upi}
                      onChange={handleChange}
                      isInvalid={!!errors.upi}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.upi}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>}
              </Row>
              {(type === "BANK" || values.type === "BANK") && <>
                <Row className="mb-3 mt-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter BankName"
                      name="bankName"
                      value={values.bankName}
                      onChange={handleChange}
                      isInvalid={!!errors.bankName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.bankName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Bank Holder</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Bank Holder"
                      name="bankHolder"
                      value={values.bankHolder}
                      onChange={handleChange}
                      isInvalid={!!errors.bankHolder}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.bankHolder}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3 mt-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Bank Account</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter BankAccount"
                      name="bankAccount"
                      value={values.bankAccount}
                      onChange={handleChange}
                      isInvalid={!!errors.bankAccount}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.bankAccount}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Bank IFSC</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Bank Ifsc"
                      name="bankIfsc"
                      value={values.bankIfsc}
                      onChange={handleChange}
                      isInvalid={!!errors.bankIfsc}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.bankIfsc}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              </>}
              {(type === "WALLET" || values.type === "WALLET") && <Row className="mt-3 mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Wallet Type</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter WalletType"
                      name="walletType"
                      value={values.walletType}
                      onChange={handleChange}
                      isInvalid={!!errors.walletType}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.walletType}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Wallet Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter WalletPhone"
                      name="walletPhone"
                      value={values.walletPhone}
                      onChange={handleChange}
                      isInvalid={!!errors.walletPhone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.walletPhone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>}
              <Row className="mt-3 mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Select Status</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) =>
                        setFieldValue("status", +e.target.value)
                      }
                      name="status"
                      className="form-control"
                      value={values.status}
                      isInvalid={!!errors.status}
                    >
                      <option value="">Select Status</option>
                      <option value={1}>Active</option>
                      <option value={0}>InActive</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default PaymentSettingAction;
